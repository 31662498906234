import React from 'react';
import { graphql } from 'gatsby';

import Container from '../components/Container';
import Instagram from '../components/Instagram';
import Quote from '../components/Quote';
import Slideshow from '../components/Slideshow';
import Button from '../components/Button';

import css from '../css/pages/index.module.css';

export default ({ data }) => {
  var slideshow = data.datoCmsFrontpage.slideshow;

  return (
    <Container>
      <section className={css.slideshow}>
        <Slideshow photos={slideshow} />
      </section>
      <section className="wrap-xl m-4">
        <div className={css.teasers}>
          <article className={css.teaser}>
            <h2>Zakázková tvorba</h2>
            <p>
              Ráda spolupracuji se zákazníky na tvorbě osobních návrhů – od výroby unikátních darků,
              doplňků a zásnubních šperků po přípravu dekorací či aranžovaní prostorů. Ráda vám
              pomůžu vnést přirodní krásu a organické prvky do jakéhokoliv projetku či nápadu.
            </p>
            <div className={css.action}>
              <Button type="link" url="/kontakt/" size="small">
                Napište mi
              </Button>
            </div>
          </article>
          <article className={css.teaser}>
            <h2>Přírodní šperky </h2>
            <p>
              Vyrábím náušnice, náhrdelníky a jiné šperky z surových minerálů které pečlivě vybírám
              a páruji. Kameny ponechávám v jejich čisté přírodní formě bez tvarování a povrchových
              úprav tak aby vynikla jejich přirozená krása. Každý kousek je jedinečný.
            </p>
            <div className={css.action}>
              <Button type="disabledButton" size="small" disabled={true}>
                Kupte si šperky
              </Button>
              <p className={css.teaserNote}>
                Sledujte{' '}
                <a href="https://www.instagram.com/isis_jewellery/" target="_blank">
                  Instagram
                </a>{' '}
                a{' '}
                <a href="https://www.facebook.com/isisjewelery/" target="_blank">
                  Facebook
                </a>{' '}
                pro informace ohledně spuštění eshopu.
              </p>
            </div>
          </article>
        </div>
      </section>
      <section className="wrap m-5">
        <Quote />
      </section>
      <div className="wrap-l m-6">
        <Instagram />
      </div>
    </Container>
  );
};

export const query = graphql`
  query {
    datoCmsFrontpage {
      quote
      quoteAuthor
      slideshow {
        fluid(maxWidth: 1400, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
